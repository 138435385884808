import React, { useEffect, useMemo, useState } from 'react';
import { formatUnits } from 'ethers/lib/utils';

import { Col, Row, Divider, Tooltip, Card, Statistic } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './AirdropClaimNew.module.scss';
import Tab from '../../../components/UI/Tab/Tab';
import TabLabel from '../../../components/UI/Tab/TabLabel';
import MainContainer from '../../../components/UI/MainContainer/MainContainer';
import { DAY, checkTime, formatNumber } from '../../../utils/utils';
// import { useInviterRecords } from '../../../hooks/useInvitationCenter';
import { AIRDROP_POSE_NAME, useAirdropPose } from '../../../store/airdrop-pose-context';
import CustomButton from '../../../components/UI/CustomButton/CustomButton';
import Phase3 from '../../../components/AirdropNew/Pose/Phase3/Phase3';

const { Countdown } = Statistic;
const ROUND_CONFIG = {
  ROUND1: {
    key: 'ROUND1',
    name: AIRDROP_POSE_NAME.AIRDROP1.key,
    text: 'Round 1',
    totalAmount25: '350,000 $POSE',
    totalAmount75: '1,050,000 $POSE',
    effectiveNode25: '1~2317',
    effectiveNode75: '2318~3011',
  },
  ROUND2: {
    key: 'ROUND2',
    name: AIRDROP_POSE_NAME.AIRDROP2.key,
    text: 'Round 2',
    totalAmount25: '288,750 $POSE',
    totalAmount75: '866,250 $POSE',
    effectiveNode25: '1~3011',
    effectiveNode75: '3012~3486',
  },
  ROUND3: {
    key: 'ROUND3',
    component: <Phase3 />,
    text: 'Round 3',
    // disabled: true,
  },
};

const AirdropClaimNew = () => {
  const { t } = useTranslation();
  // const { inviteeRecordsLength } = useInviterRecords();
  const [currentRound, setCurrentRound] = useState(ROUND_CONFIG.ROUND1.key);
  const {
    setTargetName,
    records,
    configs,
    claimAirdropPose25Record,
    claimAirdropPose25RecordState,
    claimAirdropPose75Record,
    claimAirdropPose75RecordState,
    airdropPose25Claimable,
    airdropPose75Claimable,
  } = useAirdropPose();
  const { airdropPose25Record, airdropPose75Record } = records || {};
  const { airdropPose25Config, airdropPose75Config } = configs || {};

  // 25%
  const airdropPose25Reward = formatUnits(airdropPose25Record?.poseReward ?? '0', 18);
  const airdropPose25ReferralReward = formatUnits(
    airdropPose25Record ? airdropPose25Record.poseBonus1.add(airdropPose25Record.poseBonus2) : '0',
    18,
  );
  const airdropPose25UnlockTimespan = airdropPose25Record
    ? Math.floor(airdropPose25Record.unlockTimespan.toNumber() / DAY)
    : '180';
  const airdropPose25ReleaseDate = airdropPose25Config
    ? new Date(airdropPose25Config.startTimestamp.toNumber() * 1000).toISOString().substring(0, 10)
    : '-';

  // 75%
  const airdropPose75APY = airdropPose75Record?.apy?.toString() ?? '0';
  const airdropPose75Reward = formatUnits(
    airdropPose75Record
      ? airdropPose75Record.poseReward
          .add(airdropPose75Record.poseBonus1)
          .add(airdropPose75Record.poseBonus2)
      : '0',
    18,
  );
  const airdropPose75ReleaseDate = airdropPose75Config
    ? new Date(airdropPose75Config.startTimestamp.toNumber() * 1000).toISOString().substring(0, 10)
    : '-';

  // track current round and change target name
  useEffect(() => {
    setTargetName(ROUND_CONFIG[currentRound].name);
  }, [currentRound, setTargetName]);

  const createTooltip = (name, value) => (
    <Tooltip
      color="#FFCD1D"
      title={
        <div className={styles.tooltipConent}>
          <p>{t(name, value)}</p>
        </div>
      }>
      <img src="/static/images/AirdropNew/question.svg" alt="mark" />
    </Tooltip>
  );

  const handleRoundChange = (value) => {
    setCurrentRound(value);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className={styles.Airdrop}>
      <div className={styles.banner}>
        <MainContainer className={styles.bannerContainer}>
          <div className={styles.bannerLeftWrap}>
            <div className={styles.back} onClick={handleGoBack}>
              <LeftOutlined style={{ stroke: 'white', strokeWidth: '100' }} /> Back
            </div>
            <div>
              <img
                src="/static/images/AirdropNew/Pose/tokenLogoPose.png"
                alt="back"
                width="35px"
                style={{ marginRight: '16px' }}
              />
              {t('Airdrop Token', 'Airdrop Token')}:{' '}
              <span className={styles.colorPrimary}>$POSE</span>
            </div>
          </div>
          <div>
            <Tab onChange={handleRoundChange} className={styles.tabStyles}>
              {Object.values(ROUND_CONFIG).map((item) => (
                <TabLabel
                  className={styles.tabItemStyles}
                  tag={item.key}
                  key={item.key}
                  disabled={item.disabled}>
                  {t(item.text, item.text)}
                </TabLabel>
              ))}
            </Tab>
          </div>
        </MainContainer>
      </div>
      {ROUND_CONFIG[currentRound].component ? (
        <MainContainer className={styles.container}>
          {ROUND_CONFIG[currentRound].component}
        </MainContainer>
      ) : (
        <MainContainer className={styles.container}>
          <div className={styles.cardWrap}>
            {/* 25% */}
            <div className={styles.airdropCard}>
              <Row>
                <div className={styles.airdropHeader}>
                  {t('Early Holder AIRDROP', 'Early Holder AIRDROP')}-25%
                </div>
              </Row>
              <Card className={styles.airdropCardStyle}>
                <Row>
                  <Col span={12}>
                    <div>{t('My Airdrop Amount', 'My Airdrop Amount')}</div>
                  </Col>
                  <Col span={12}>
                    <div>
                      {t('My Referral rewards', 'My Referral rewards')}{' '}
                      {createTooltip(
                        'referral-desc',
                        t(
                          'referral-desc',
                          "'When someone you referred earned airdrop, You’ll receive 5% of the value of their airdrop extra. However, once you refer three people, then you’ll get 15% of the airdrop value their referrals are receiving",
                        ),
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className={styles.airdropAmount}>
                      {formatNumber(airdropPose25Reward, '0,0.[0000]')}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropAmount}>
                      {formatNumber(airdropPose25ReferralReward, '0,0.[0000]')}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className={classNames(styles.airdropPrimary, styles.airdropBold)}>
                      $POSE
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropPrimary, styles.airdropBold)}>
                      $POSE
                    </div>
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col span={12}>
                    <div>{t('Total Airdrop Amount', 'Total Airdrop Amount')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropValue, styles.airdropPrimary)}>
                      {ROUND_CONFIG[currentRound].totalAmount25}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div>{t('Effective Zepoch Number', 'Effective Zepoch Number')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropValue, styles.airdropPrimary)}>
                      {ROUND_CONFIG[currentRound].effectiveNode25}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={12}>
                    <div>{t('My Referrals', 'My Referrals')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropValue, styles.airdropPrimary)}>
                      {formatNumber(inviteeRecordsLength?.data)}
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col span={12}>
                    <div>
                      {t('My Vesting Period', 'My Vesting Period')}{' '}
                      {createTooltip(
                        'vesting-desc',
                        t(
                          'vesting-desc',
                          'Vesting period is auto generated from [30,180] days depends on your 3Base points.',
                        ),
                      )}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropValue}>
                      {formatNumber(airdropPose25UnlockTimespan)} {t('Days', 'Days')}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div>{t('Release Date', 'Release Date')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropValue}>{airdropPose25ReleaseDate}</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div>{t('Claimable')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropValue}>
                      {formatNumber(formatUnits(airdropPose25Claimable || '0', 18), '0,0.[0000]')}{' '}
                      POSE
                    </div>
                  </Col>
                </Row>
                <CustomButton
                  className={styles.claimButton}
                  disabled={airdropPose25Record?.isReadyToClaim === false}
                  onClick={claimAirdropPose25Record}
                  loading={claimAirdropPose25RecordState.isLoading}>
                  {airdropPose25Record?.isReadyToClaim === false ? (
                    <Countdown
                      className={styles.countdown}
                      value={
                        ((airdropPose25Record?.lastUnlockTimestamp?.toNumber() || 0) + DAY * 15) *
                        1000
                      }
                      format="D:HH:mm:ss"
                    />
                  ) : (
                    'Claim'
                  )}
                </CustomButton>
              </Card>
            </div>
            {/* 75% */}
            <div className={styles.airdropCard}>
              <Row>
                <div className={styles.airdropHeader}>
                  {t('New Holder AIRDROP', 'New Holder AIRDROP')}-75%
                </div>
              </Row>
              <Card className={styles.airdropCardStyle}>
                <Row>
                  <Col span={12}>
                    <div>
                      APY{' '}
                      {createTooltip(
                        'apy-desc',
                        t(
                          'apy-desc',
                          'The maximum airdrop amount a new node holder can receive is 150% of the value of their nodes, with the unlock period spanning over 24 months. The airdrop structure for new nodes, those with an ID number between 2318 and 3011, is as follows: Node holders with fewer than 5 nodes will receive 50% of their nodes’ value. Those with 5–14 nodes will receive 100% of their nodes’ value. Node holders owning 15 or more nodes will be awarded 150% of their nodes’ value.',
                        ),
                      )}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      {t('My Airdrop Amount', 'My Airdrop Amount')}{' '}
                      {createTooltip(
                        'referral-desc',
                        t('Referral Bonus Included', 'Referral Bonus Included'),
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className={styles.airdropAmount}>{formatNumber(airdropPose75APY)}%</div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropAmount}>
                      {formatNumber(airdropPose75Reward, '0,0.[0000]')}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} />
                  <Col span={12}>
                    <div className={classNames(styles.airdropPrimary, styles.airdropBold)}>
                      $POSE
                    </div>
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col span={12}>
                    <div>{t('Total Airdrop Amount', 'Total Airdrop Amount')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropValue, styles.airdropPrimary)}>
                      {ROUND_CONFIG[currentRound].totalAmount75}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div>{t('Effective Zepoch Number', 'Effective Zepoch Number')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropValue, styles.airdropPrimary)}>
                      {ROUND_CONFIG[currentRound].effectiveNode75}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={12}>
                    <div>{t('My Referrals', 'My Referrals')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={classNames(styles.airdropValue, styles.airdropPrimary)}>
                      {formatNumber(inviteeRecordsLength?.data)}
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col span={12}>
                    <div>{t('My Vesting Period', 'My Vesting Period')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropValue}>24 {t('Months', 'Months')}</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div>{t('Release Date', 'Release Date')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropValue}>{airdropPose75ReleaseDate}</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div>{t('Claimable')}</div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.airdropValue}>
                      {formatNumber(formatUnits(airdropPose75Claimable || '0', 18), '0,0.[0000]')}{' '}
                      POSE
                    </div>
                  </Col>
                </Row>
                <CustomButton
                  className={styles.claimButton}
                  disabled={airdropPose75Record?.isReadyToClaim === false}
                  onClick={claimAirdropPose75Record}
                  loading={claimAirdropPose75RecordState.isLoading}>
                  {airdropPose75Record?.isReadyToClaim === false ? (
                    <Countdown
                      className={styles.countdown}
                      value={
                        ((airdropPose75Record?.lastUnlockTimestamp?.toNumber() || 0) + DAY * 15) *
                        1000
                      }
                      format="D:HH:mm:ss"
                    />
                  ) : (
                    'Claim'
                  )}
                </CustomButton>
              </Card>
            </div>
          </div>

          <a
            href="https://nautchain.medium.com/introducing-the-poseiswap-zepoch-node-holder-airdrop-bc0b5c23af0a"
            target="_blank"
            rel="noreferrer">
            <div className={styles.howToEarn}>{t('Airdrop Rules', 'Airdrop Rules')}</div>
          </a>
        </MainContainer>
      )}
    </div>
  );
};

export default AirdropClaimNew;
