import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Col, Row, Statistic, Tooltip } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

import CustomButton from '../../../UI/CustomButton/CustomButton';
import HashLinkWithQuery from '../../../HashLinkWithQuery/HashLinkWithQuery';
import CustomModal from '../../../UI/CustomModal/CustomModal';
import { DAY, checkTime, formatNumber } from '../../../../utils/utils';
import { useAirdropPose } from '../../../../store/airdrop-pose-context';
// import { usePOSEPrice } from '../../../../hooks/usePOSEPrice';
import { useNFTRecords } from '../../../../hooks/useNFT';

import styles from './Phase3.module.scss';
import commonStyles from '../../../../common.module.scss';

const { Countdown } = Statistic;

const DESC = [
  { id: 0, percent: '0.5x', count: 1 },
  { id: 1, percent: '1x', count: 5 },
  { id: 2, percent: '1.5x', count: 15 },
  { id: 3, percent: '2x', count: 30 },
  { id: 4, percent: '2.5x', count: 50 },
];

const Phase3 = () => {
  const { t } = useTranslation();

  const [claimVisible, setClaimVisible] = useState(false);
  // const posePrice = usePOSEPrice();
  const {
    phase3EstimatedUnlockInfo: estimatedUnlockInfo,
    phase3NeedToSync: needToSync,
    phase3Claimable: claimable,
    phase3UnlockInfo: unlockInfo,
    phase3ActivateForPose,
    phase3ActivateForPoseState,
    phase3SyncUnlockForPose,
    phase3SyncUnlockForPoseState,
    phase3ClaimForPose,
    phase3ClaimForPoseState,
  } = useAirdropPose();

  const unlockReady = useMemo(() => {
    if (!unlockInfo?.lastClaimTimestamp) return true;
    return checkTime(unlockInfo.lastClaimTimestamp.toNumber() * 1000 + DAY * 1000 * 15);
  }, [unlockInfo?.lastClaimTimestamp]);
  const { data: nftRecords } = useNFTRecords();
  const hasNewNode = useMemo(
    () => !!nftRecords?.zepochNodeRecords?.some((item) => item.tokenId > 3486),
    [nftRecords],
  );
  const toggleClaimVisible = () => {
    setClaimVisible((prev) => !prev);
  };

  return (
    <>
      <div className={styles.RewardsPlan}>
        {/* <h2 className={classNames(commonStyles.gradientTitle, styles.title)}>
          {t('3Base Rewards Plan', '3Base Rewards Plan')}
        </h2> */}
        <div className={styles.planWrap}>
          <div className={styles.planCard}>
            {!hasNewNode && unlockInfo?.level?.gt(0) ? (
              <div className={classNames(styles.infoWrap, styles.infoWrapActivate)}>
                <p>{t('Activated', 'Activated')}</p>
              </div>
            ) : (
              <div className={styles.infoWrap}>
                <h2 className={commonStyles.gradientTitle}>{t('Reward Ratio', 'Reward Ratio')}</h2>
                <p>
                  {formatNumber(unlockInfo?.apy?.toNumber() ? unlockInfo.apy.toNumber() / 100 : 0)}
                  {unlockInfo?.apy?.toNumber() ? 'x' : ''}
                </p>
              </div>
            )}

            <div className={styles.actionWrap}>
              <HashLinkWithQuery to="/participate">
                <CustomButton secondary rounded className={styles.linkButton}>
                  {t('Get Zepoch', 'Get Zepoch')}
                </CustomButton>
              </HashLinkWithQuery>
              <HashLinkWithQuery to="/profile#referral">
                <CustomButton secondary rounded className={styles.linkButton}>
                  {t('Invite Friends', 'Invite Friends')}
                </CustomButton>
              </HashLinkWithQuery>
            </div>
          </div>
          <div className={styles.planCard}>
            <div className={styles.infoItem}>
              <div className={styles.dataWrap}>
                <h2 className={classNames(commonStyles.gradientTitle, styles.dataTitle)}>
                  {t('Claimable POSE', 'Claimable POSE')}{' '}
                  <Tooltip
                    color="#EDEDED"
                    title={
                      <div className={styles.tooltipConent}>
                        <p>
                          {t(
                            'airdrop-pose-phase3-desc',
                            'POSE rewards will be vested in 24 month. You can withdraw every second.',
                          )}
                        </p>
                      </div>
                    }>
                    <img src="/static/images/Rewards/questionMark.svg" alt="mark" />
                  </Tooltip>
                </h2>
                <h3>{formatNumber(formatUnits(claimable ?? '0', 18), '0,0[.]0000')} POSE</h3>
                <h4>
                  &#8776;{' '}
                  {formatNumber(claimable && formatUnits(claimable.mul(2), 18), '$0,0[.]0000')}
                </h4>
              </div>
              <CustomButton
                secondary
                rounded
                className={styles.linkButton}
                onClick={toggleClaimVisible}>
                {t('Claim', 'Claim')}
              </CustomButton>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.dataWrap}>
                <h2 className={classNames(commonStyles.gradientTitle, styles.dataTitle)}>
                  {t('Estimate Reward Ratio', 'Estimate Reward Ratio')}
                </h2>
                {!hasNewNode ? (
                  <h3>-</h3>
                ) : (
                  <h3>
                    {formatNumber(
                      estimatedUnlockInfo?.apy?.toNumber()
                        ? estimatedUnlockInfo.apy.toNumber() / 100
                        : 0,
                    )}
                    {estimatedUnlockInfo?.apy?.toNumber() ? 'x' : ''}
                  </h3>
                )}

                <h4>
                  <Tooltip
                    color="#EDEDED"
                    title={
                      <div className={styles.tooltipConent}>
                        <p>{t('pose-price-fixed', 'The system uses a fixed POSE price at $2')}</p>
                      </div>
                    }>
                    {t('Calculated POSE Price', 'Calculated POSE Price')}:{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>{' '}
                  {formatNumber(2, '$0,0.[00000]')}
                </h4>
              </div>
              {/* {unlockInfo?.level?.gt(0) ? (
                <CustomButton
                  rounded
                  className={styles.linkButton}
                  disabled={!needToSync}
                  onClick={phase3SyncUnlockForPose}
                  loading={phase3SyncUnlockForPoseState?.isLoading}>
                  {t('Update', 'Update')}
                </CustomButton>
              ) : (
                <CustomButton
                  rounded
                  className={styles.linkButton}
                  disabled={!estimatedUnlockInfo?.level?.gt(0)}
                  onClick={phase3ActivateForPose}
                  loading={phase3ActivateForPoseState?.isLoading}>
                  {t('Activate', 'Activate')}
                </CustomButton>
              )} */}
            </div>
          </div>
        </div>
        <Row className={styles.descWrap} gutter={[10, 10]} justify="center">
          {DESC.map((item) => (
            <Col key={item.id} span={12} md={8}>
              <div className={styles.descItem}>
                <h2 className={styles.percentage}>{item.percent}</h2>
                <div className={styles.descCountWrap}>
                  <h2>{t('Owned Zepoch', 'Owned Zepoch')} &gt;=</h2>
                  <div className={styles.count}>{item.count}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <CustomModal
        open={claimVisible}
        onCancel={toggleClaimVisible}
        showTitle
        title={t('Claim Rewards', 'Claim Rewards')}
        className={styles.claimModal}>
        <div className={styles.claimModalContent}>
          <div className={styles.claimModalInfoItem}>
            <h2>{t('Claimable POSE', 'Claimable POSE')}</h2>
            <p>
              {formatNumber(formatUnits(claimable ?? '0', 18), '0,0[.]0000')}
              <span> POSE</span>
            </p>
          </div>
          <div className={styles.claimModalInfoItem}>
            <h2>
              {t('Claimable Bonus POSE', 'Claimable Bonus POSE')}
              {/* <Tooltip
                color="#EDEDED"
                title={
                  <div className={styles.tooltipConent}>
                    <p>
                      {t(
                        'pose-airdrop3-bonus-1',
                        'Earn 5% of your referrals rewards if number (owned zepoch + referred zepoch ) >= 5',
                      )}
                    </p>
                    <br />
                    <p>
                      {t(
                        'pose-airdrop3-bonus-2',
                        'Earn 15% of your referrals refers’ rewards if number (owned zepoch + referred zepoch ) >= 15',
                      )}
                    </p>
                  </div>
                }>
                {' '}
                <img src="/static/images/Rewards/questionMark.svg" alt="mark" />
              </Tooltip> */}
            </h2>
            <p>
              {formatNumber(formatUnits(unlockInfo?.poseBonusClaimable ?? '0', 18), '0,0[.]0000')}
              <span> POSE</span>
            </p>
          </div>
          <div className={styles.claimModalInfoItem}>
            <h2 className={commonStyles.gradientTitle}>
              {t('Total Locked POSE', 'Total Locked POSE')}
            </h2>
            <p>
              {formatNumber(formatUnits(unlockInfo?.posePlaned ?? '0', 18), '0,0[.]0000')}
              <span> POSE</span>
            </p>
          </div>
          <div className={styles.claimModalInfoItem}>
            <h2 className={commonStyles.gradientTitle}>
              {t('Total Claimed POSE', 'Total Claimed POSE')}
            </h2>
            <p>
              {formatNumber(
                formatUnits(
                  unlockInfo?.poseClaimedCumulative?.add(unlockInfo?.poseBonusClaimedCumulative) ??
                    '0',
                  18,
                ),
                '0,0[.]0000',
              )}
              <span> POSE</span>
            </p>
          </div>

          <p className={styles.desc}>
            {t(
              'airdrop-pose-phase3-desc',
              'POSE rewards will be vested in 24 month. You can withdraw every second.',
            )}
          </p>
          <CustomButton
            className={styles.linkButton}
            rounded
            disabled={!unlockReady}
            loading={phase3ClaimForPoseState.isLoading}
            onClick={phase3ClaimForPose}>
            {unlockReady ? (
              t('Claim', 'Claim')
            ) : (
              <Countdown
                className={styles.countdown}
                value={((unlockInfo?.lastClaimTimestamp?.toNumber() || 0) + DAY * 15) * 1000}
                format="D:HH:mm:ss"
              />
            )}
          </CustomButton>
        </div>
      </CustomModal>
    </>
  );
};

export default Phase3;
