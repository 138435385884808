import { useEffect, useState, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import AOS from 'aos';

import WalletMenu from './components/WalletMenu/WalletMenu';
import { useWallet } from './store/wallet-context';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { usePages } from './constants/pages';
import RedirectWithQuery from './components/RedirectWithQuery/RedirectWithQuery';
import CustomAlert from './components/CustomAlert/CustomAlert';

import 'aos/dist/aos.css';
import 'antd/dist/antd.css';
import './App.css';
import KYCModal from './components/KYCModal/KYCModal';
import { useGeolocation } from './hooks/useGeolocation';
import { BLOCKED_COUNTRY_BY_IP } from './constants/misc';
import Blocked from './views/Blocked/Blocked';
import { BLACK_LIST } from './constants/addresses';
import ComingSoon from './views/ComingSoon/ComingSoon';

function App() {
  const PAGES = usePages();
  const CONCRETE_PAGES = useMemo(
    () => PAGES.filter((item) => !!item.component && !item.maintenance),
    [PAGES],
  );

  const { account } = useWallet();
  const [pages, setPages] = useState(CONCRETE_PAGES.filter((item) => !item.protected));

  useEffect(() => {
    setPages(CONCRETE_PAGES.filter((item) => !item.protected || account));
  }, [account, CONCRETE_PAGES]);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  const geolocation = useGeolocation();
  if (
    (process.env.REACT_APP_BLOCK_BY_IP && BLOCKED_COUNTRY_BY_IP.includes(geolocation.country)) ||
    BLACK_LIST.map((a) => a.toLowerCase()).includes(account?.toLowerCase())
  ) {
    return <Blocked />;
  }

  return (
    <>
      {/* <CustomAlert /> */}
      <div className="App">
        {/* <Header /> */}
        <Switch>
          {/* {pages.map((item) => {
            const PageComponent = item.component;
            return (
              <Route path={item.path} exact={item.exact} key={item.text}>
                <PageComponent />
              </Route>
            );
          })} */}
          <Route path="/">
            <ComingSoon />
          </Route>
          <RedirectWithQuery to="/about" />
        </Switch>
        {/* <Footer /> */}
        {/* <WalletMenu />
        <KYCModal /> */}
      </div>
    </>
  );
}

export default App;
