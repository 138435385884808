import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';

const LINK_COLUMN = {
  PRODUCT: 'Product',
  COMPANY: 'Company',
  LEARN: 'Learn',
  SOCIAL: 'Social',
};

const LINK_LIST = {
  [LINK_COLUMN.PRODUCT]: [
    { text: 'Github', link: 'https://github.com/3base-Labs' },
    { text: 'Docs', link: 'https://docs.3base.org/' },
  ],
  [LINK_COLUMN.COMPANY]: [
    { text: 'About Us', link: 'https://www.3base.org/about' },
    { text: 'Privacy', link: 'https://www.3base.org/privacy-policy' },
    // { text: 'MediaKit', link: 'https://zebec.io/media-kit' },
  ],
  [LINK_COLUMN.LEARN]: [
    { text: 'What is 3Base?', link: 'https://docs.3base.org/' },
    { text: 'What is Solana', link: 'https://docs.solana.com/' },
  ],
  [LINK_COLUMN.SOCIAL]: [
    { text: 'Discord', link: 'https://discord.com/invite/KgcF5nzytv' },
    { text: 'Twitter', link: 'https://x.com/3baseorg' },
    { text: 'Telegram', link: 'https://t.me/org_3base' },
    // { text: 'Telegram (Zebec Operation Horizon)', link: 'https://t.me/zebechorizon' },
    { text: 'Medium', link: 'https://medium.com/@3baseofficial' },
  ],
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.Footer}>
        <div className={`${styles.FooterColumn} ${styles.FlexColumn}`}>
          <div className={styles.FooterLogo}>
            <img src="/static/images/Header/logo.svg" alt="zebec" />
            {/* <h2>Zebec</h2> */}
          </div>
          <br />
        </div>
        <div className={styles.LinkWrap}>
          <Row gutter={[16, 32]}>
            {Object.values(LINK_COLUMN).map((item) => (
              <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} key={item}>
                <div className={styles.LinkColumn}>
                  <h2>{t(item)}</h2>
                  {LINK_LIST[item].map((linkItem) => (
                    <a
                      href={linkItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={linkItem.text}>
                      {t(linkItem.text)}
                    </a>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <h2 className={styles.CopyRight}>© {new Date().getFullYear()} 3Base. All rights reserved.</h2>
    </>
  );
};

export default Footer;
