import { BigNumber } from 'ethers';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useIsMounted } from './useIsMounted';
import { useUpdate } from './useUpdate';

export const useZBCPrice = () => {
  const isMounted = useIsMounted();
  const { updateByTimer } = useUpdate();
  const [zbcPrice, setZbcPrice] = useState({});

  const fetchPrice = useCallback(async () => {
    let data = {};
    try {
      const res = await axios.get(`https://api2.zebec.io/price`);
      if (res.status === 200) {
        data = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    return data;
  }, []);

  useEffect(() => {
    fetchPrice().then((res) => {
      if (!Object.keys(res).length || !isMounted.current) {
        return;
      }
      setZbcPrice({
        zbcPriceFixedPoint18: BigNumber.from(res?.price ?? 0),
        timestamp: BigNumber.from(res?.timestamp ?? 0),
        v: res.v,
        r: res.r,
        s: res.s,
      });
    });
  }, [fetchPrice, isMounted, updateByTimer]);
  return zbcPrice;
};
