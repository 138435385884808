import React, { useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, message } from 'antd';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ethers } from 'ethers';

// eslint-disable-next-line import/no-unresolved
import { useTranslation, Trans } from 'react-i18next';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import {
  NFT_TYPE,
  SINGLE_TRANSACTION_NFT_LIMIT,
  ZPOINTSTATISTIC_POINT_KEYS,
} from '../../constants/misc';
import CustomModal from '../../components/UI/CustomModal/CustomModal';
import { usePhase1Config, useUnlockZBC, useGetPurchaseInfo } from '../../hooks/useManager';
import { formatNumber } from '../../utils/utils';
import { useInviterRecords } from '../../hooks/useInvitationCenter';
import { useNFTRecords } from '../../hooks/useNFT';

import styles from './Profile.module.scss';
import commonStyles from '../../common.module.scss';
import NavLinkWithQuery from '../../components/NavLinkWithQuery/NavLinkWithQuery';

const REFERRAL_DESC = [
  // 'referral-earning-1',
  // 'Earn 1,000 3Base Points for each successful referral in Phase 1.',
  'referral-earning-2',
  // 'Earn points equal to 50% of the Zepoch price (Zepoch Price * .5) for each successful referral in Phase 2.',
  'referral-earning-3',
  // 'Earn a 5% rebate in USDT/BUSD of the Zepoch Price (Zepoch Price * .05) for each successful referral in Phase 2.',
  // 'referral-earning-4',
  // 'Starting after 15,000 Zepoch Nodes are sold, earn up to 20% of a daily ZBC airdrop for successful referrals.',
];

const NftDetail = ({ nftRecord, phase1ZbcLockAmount, unlock, isLoading }) => {
  const imgPath = `/static/images/NFT/${
    nftRecord.nftType === NFT_TYPE.ZEPOCH ? 'zepoch' : 'themis'
  }.png`;
  const { t } = useTranslation();
  const unlockTime =
    nftRecord?.phase1ZbcUserLocked?.unlockTimestamp &&
    new Date(nftRecord.phase1ZbcUserLocked.unlockTimestamp * 1000).toISOString().substring(0, 10);

  return (
    <div className={styles.NftDetail}>
      <div className={styles.tagWrap}>
        <div className={classNames(commonStyles.gradientTitle, styles.phaseTag)}>
          {t('Phase', 'Phase')} {nftRecord.phase}
        </div>
      </div>
      <img src={imgPath} alt="node" />
      <div className={styles.contentWrap}>
        <h2 className={classNames(styles.nftId, commonStyles.gradientTitle)}>{`#${formatNumber(
          nftRecord.tokenId,
          '000',
        )}`}</h2>
        <h1 className={classNames(styles.title, commonStyles.gradientTitle)}>
          {t(nftRecord.nftType)}
        </h1>
        <div className={styles.infoItemGroup}>
          <div className={styles.infoItem}>
            <h2>{t('ZBC Staked', 'ZBC Staked')}</h2>
            <p>{formatNumber(phase1ZbcLockAmount)}</p>
          </div>
          <div
            className={classNames(styles.infoItem, {
              [styles.unlockable]: nftRecord?.phase1ZbcUserLocked?.unlockable,
            })}>
            <h2>{t('ZBC Unlock Time', 'ZBC Unlock Time')}</h2>
            <p>{unlockTime}</p>
          </div>
        </div>
        <CustomButton
          className={styles.nodeInfoContainerButton}
          rounded
          loading={isLoading}
          onClick={() => {
            unlock([nftRecord.tokenId]);
          }}>
          {t('Redeem', 'Redeem')}
        </CustomButton>
      </div>
    </div>
  );
};

NftDetail.propTypes = {
  // eslint-disable-next-line
  nftRecord: PropTypes.objectOf(PropTypes.any).isRequired,
  phase1ZbcLockAmount: PropTypes.number,
  unlock: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

NftDetail.defaultProps = {
  phase1ZbcLockAmount: 0,
};

const ListPlaceholder = () => (
  <div className={styles.ListPlaceholder}>
    <img src="/static/images/Common/emptyBox.svg" alt="empty" />
    <NavLinkWithQuery to="/participate">
      <CustomButton rounded>
        <Trans i18nKey="Get Zepoch Node"> Get Zepoch Node</Trans>
      </CustomButton>
    </NavLinkWithQuery>
  </div>
);

export default function Profile() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedTab, setSelectedTab] = useState(NFT_TYPE.ZEPOCH);
  const [nftDetailVisible, setNftDetailVisible] = useState(false);
  const [selectedNft, setSelectedNft] = useState({});
  const [referralDetailVisible, setReferralDetailVisible] = useState(true);

  const { data: purchaseInfo } = useGetPurchaseInfo();
  const { inviterRecords, inviteeRecordsLength, inviteeZepochBalance } = useInviterRecords();
  const referralCode = inviterRecords?.data?.invitationCode;
  const referralLink = `${window.location.origin}/#/participate?referral=${referralCode}`;
  const { data: nftRecords } = useNFTRecords();
  const { zepochNodeRecords, themisGavelRecords } = nftRecords;
  const { data: phase1Config } = usePhase1Config();
  const { phase1ZbcLockAmount } = phase1Config;
  const { state: unlockZBCState, unlockZBC } = useUnlockZBC();

  const { t } = useTranslation();

  const totalInviteeNodes = useMemo(() => {
    if (!inviteeZepochBalance) {
      return 0;
    }
    return inviteeZepochBalance.reduce((accum, item) => accum + item, 0);
  }, [inviteeZepochBalance]);

  const toggleNftDetailVisible = () => {
    setNftDetailVisible((prev) => !prev);
  };

  const toggleReferralDetailVisible = () => {
    setReferralDetailVisible((prev) => !prev);
  };

  const handleUnlockZBC = async (tokenIds) => {
    if (unlockZBCState.isLoading) {
      return;
    }
    try {
      await unlockZBC(tokenIds);
    } catch (error) {
      message.error('unlock failed');
    }
  };

  const getNftList = () => {
    if (selectedTab === NFT_TYPE.ZEPOCH) {
      if (zepochNodeRecords?.length) {
        return zepochNodeRecords?.map((nftRecord) => (
          <Col
            span={12}
            sm={12}
            md={6}
            xl={4}
            onClick={() => {
              if (
                !nftRecord?.phase1ZbcUserLocked?.unlockable ||
                nftRecord?.phase1ZbcUserLocked?.unlocked
              ) {
                return;
              }
              setSelectedNft(nftRecord);
              toggleNftDetailVisible();
            }}
            key={nftRecord.tokenId}>
            <div className={styles.nodeContainer}>
              <div className={styles.tagWrap}>
                <div className={classNames(commonStyles.gradientTitle, styles.phaseTag)}>
                  {t('Phase', 'Phase')} {nftRecord.phase}
                </div>
                {nftRecord?.phase1ZbcUserLocked?.unlockable &&
                  !nftRecord?.phase1ZbcUserLocked?.unlocked && <div className={styles.dot} />}
              </div>

              <img src="/static/images/NFT/zepoch.png" alt="Node" />
              <h3>{`${t(NFT_TYPE.ZEPOCH)} #${formatNumber(nftRecord.tokenId, '000')}`}</h3>
            </div>
          </Col>
        ));
      }
      return <ListPlaceholder />;
    }
    if (selectedTab === NFT_TYPE.THEMIS) {
      if (themisGavelRecords?.length) {
        return themisGavelRecords?.map((nftRecord) => (
          <Col span={12} sm={12} md={6} xl={4} key={nftRecord.tokenId}>
            <div className={styles.nodeContainer}>
              <img src="/static/images/NFT/themis.png" alt="Node" />
              <h3>{`${t(NFT_TYPE.THEMIS)} #${formatNumber(nftRecord.tokenId, '000')}`}</h3>
            </div>
          </Col>
        ));
      }
      return <ListPlaceholder />;
    }
    return null;
  };
  return (
    <div
      className={styles.Profile}
      // style={{ backgroundImage: 'url(/static/images/About/bg.jpg)' }}
    >
      <div className={styles.banner}>
        <Row gutter={[8, 8]} align="bottom" className={styles.bannerContainer}>
          <Col span={12} sm={12} lg={6}>
            <div className={styles.bannerInfo}>
              <h2>{t('Zepoch Amount', 'Zepoch Amount')}</h2>
              <p>{formatNumber(zepochNodeRecords?.length)}</p>
            </div>
          </Col>
          <Col span={12} sm={12} lg={6}>
            <div className={styles.bannerInfo}>
              <h2>{t('3Base Points', '3Base Points')}</h2>
              <p>
                {formatNumber(
                  !!Object.keys(purchaseInfo).length &&
                    ethers.utils.formatEther(
                      ZPOINTSTATISTIC_POINT_KEYS.reduce(
                        (accum, key) => accum.add(purchaseInfo[key]),
                        ethers.constants.Zero,
                      ),
                    ),
                )}
              </p>
            </div>
          </Col>
          <Col span={12} sm={12} lg={6}>
            <div className={styles.bannerInfo}>
              <h2>{t('Referrals and nodes', 'Referrals/Nodes')}</h2>
              <p>
                {formatNumber(inviteeRecordsLength?.data)} / {formatNumber(totalInviteeNodes)}
              </p>
            </div>
          </Col>
          <Col span={12} sm={12} lg={6}>
            <div className={styles.bannerInfo}>
              <h2>{t('Referral Earned', 'Referral Earned')}</h2>
              <p className={styles.small}>
                {formatNumber(ethers.utils.formatEther(purchaseInfo.usdCommission ?? '0'))} USD
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.contentContainer}>
        <Row className={styles.tabsContainer} gutter={[16, 16]}>
          <Col span={24}>
            <Row>
              {Object.values(NFT_TYPE).map((item) => (
                <Col
                  span={12}
                  className={classNames(styles.tabSelector, {
                    [styles.tabSelected]: item === selectedTab,
                  })}
                  onClick={() => {
                    setSelectedTab(item);
                  }}
                  key={item}>
                  {t(item)}
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={24}>
            <Row className={styles.nftList} gutter={[16, 16]}>
              {getNftList()}
            </Row>
          </Col>

          {/* {selectedTab === NFT_TYPE.ZEPOCH && !!unlockableZepochNode?.length && (
            <Col span={24}>
              <Row className={styles.nodeInfosListContainer}>
                <Col span={24} sm={12} lg={6}>
                  <div className={styles.nodeInfoContainer}>
                    <h3>{t('Nodes Unlockable', 'Nodes Unlockable')}</h3>
                    <p>{formatNumber(unlockableZepochNode?.length)}</p>
                    <CustomButton
                      className={styles.nodeInfoContainerButton}
                      rounded
                      loading={unlockZBCState.isLoading}
                      onClick={handleUnlockZBC}>
                      {t('Unlock', 'Unlock')}
                    </CustomButton>
                  </div>
                </Col>
              </Row>
            </Col>
          )} */}
        </Row>

        <div className={styles.referralContainer} id="referral">
          <Row className={styles.mobileJustifyCenter}>
            <h2 className={styles.secondaryTitle}>{t('Referrals', 'Referrals')}</h2>
          </Row>
          {referralCode && (
            <>
              <h3>
                {t(
                  'Invite your friends to earn exclusive rewards',
                  'Invite your friends to earn exclusive rewards',
                )}
              </h3>
              <p>{t('Your Invitation Link', 'Your Invitation Link')}</p>
              <Row className={styles.referralLinkWrap} justify="center" align="middle">
                <Col span={24} md={18}>
                  <input placeholder={referralLink} disabled />
                </Col>
                <Col md={6}>
                  <CopyToClipboard
                    text={referralLink}
                    onCopy={() => {
                      message.success(t('Referral link copied', 'Referral link copied'));
                    }}>
                    <CustomButton secondary rounded className={styles.copyButton}>
                      {t('Copy', 'Copy')}
                    </CustomButton>
                  </CopyToClipboard>
                </Col>
              </Row>
            </>
          )}
          {!referralCode && (
            <>
              <h3>
                <Trans i18nKey="participate-to-activate">
                  Please participate in the 3Base Operation Horizon to activate the referral system
                </Trans>
              </h3>
              <NavLinkWithQuery to="/participate">
                <CustomButton rounded> {t('Get Zepoch Node', 'Get Zepoch Node')}</CustomButton>
              </NavLinkWithQuery>
            </>
          )}
          <button
            className={styles.referralDetailButton}
            type="button"
            onClick={toggleReferralDetailVisible}>
            {referralDetailVisible ? `${t('Detail', 'Details')} ←` : `${t('Detail', 'Details')} →`}
          </button>
          <ul
            className={classNames(styles.referralDetail, { [styles.show]: referralDetailVisible })}>
            {REFERRAL_DESC.map((item) => (
              <li key={item}>{t(item)}</li>
            ))}
          </ul>
        </div>
      </div>
      <CustomModal open={nftDetailVisible} onCancel={toggleNftDetailVisible} width={300}>
        <NftDetail
          nftRecord={selectedNft}
          phase1ZbcLockAmount={phase1ZbcLockAmount}
          isLoading={unlockZBCState.isLoading}
          unlock={handleUnlockZBC}
        />
      </CustomModal>
    </div>
  );
}
