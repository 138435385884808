import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NavLinkWithQuery from '../../components/NavLinkWithQuery/NavLinkWithQuery';
import RedirectWithQuery from '../../components/RedirectWithQuery/RedirectWithQuery';
import NautilusGasShare from '../NautilusGasShare/NautilusGasShare';

import MainContainer from '../../components/UI/MainContainer/MainContainer';
import TokenPool from '../TokenPool/TokenPool';

import styles from './Farm.module.scss';

const Farm = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const ROUTES = useMemo(
    () => [
      {
        name: t('ZBC-USDT LP', 'ZBC-USDT LP'),
        shortName: t('ZBC-USDT LP'),
        path: '/lp',
        component: TokenPool,
      },
      {
        name: t('Nautilus Gas Rewards', 'Nautilus Gas Rewards'),
        shortName: t('Nautilus Gas Rewards', 'Nautilus Gas Rewards'),
        path: '/gasShare',
        component: NautilusGasShare,
      },
    ],
    [t],
  );

  return (
    <>
      <div className={styles.header}>
        <MainContainer className={styles.headerContainer}>
          <div className={styles.headerTitle}>
            <h2>{t('3Base Farm', '3Base Farm')}</h2>
            {/* <p>{t('Stake LP token to earn', 'Stake ZBC-USDT LP token to earn')}</p> */}
          </div>
        </MainContainer>
      </div>
      <div className={styles.Farm}>
        <MainContainer className={styles.titleWrap}>
          <Switch>
            {ROUTES.map((item) => (
              <Route path={`${path}${item.path}`} exact key={item.shortName}>
                <h2>{item.name}</h2>
              </Route>
            ))}
            <RedirectWithQuery to={`${path}${ROUTES?.[0]?.path}`} />
          </Switch>
          <div className={styles.navWrap}>
            {ROUTES.map((item) => (
              <NavLinkWithQuery
                key={item.shortName}
                to={`${path}${item.path}`}
                className={(isActive) => classNames(styles.navItem, { [styles.active]: isActive })}>
                {item.shortName}
              </NavLinkWithQuery>
            ))}
          </div>
        </MainContainer>
        <Switch>
          {ROUTES.map((item) => {
            const Component = item.component;
            return (
              <Route path={`${path}${item.path}`} exact key={item.shortName}>
                <Component />
              </Route>
            );
          })}
        </Switch>
      </div>
    </>
  );
};

export default Farm;
