export const MOBILE_BREAK_POINT = 768;

export const WEBSITE_URL = 'https://horizon.3base.org/';

export const DEFAULT_REFERRAL_CODE = 'MAXS1KS7';

export const NFT_TYPE = {
  ZEPOCH: 'Zepoch Node',
  THEMIS: 'Themis Gavel',
};

export const PHASE_1_NFT_AMOUNT = 288;

export const TOTAL_NFT_AMOUNT = 30000;

export const ZPOINTSTATISTIC_POINT_KEYS = [
  'zPointViaPurchase',
  'zPointViaReferral',
  'zPointViaBonus',
  'zPointViaVote',
];

// timestamps
export const WHITELIST_START_TIMESTAMP = 1664802000; // 10/3 13:00 UTC
export const WHITELIST_END_TIMESTAMP = 1665493200; // 10/11 13:00 UTC

export const SINGLE_TRANSACTION_NFT_LIMIT = 50;

export const BLOCKED_COUNTRY_BY_IP = ['US'];

export const BLOCKED_COUNTRY = [
  'Myanmar',
  "Cote D'lvoire",
  'Cuba',
  'Congo',
  'Congo (D.R.C)',
  'Iran',
  'North Korea',
  'Sudan',
  'Syria',
  'Afghanistan',
  'Russian Federation',
  'China',
  'Ukraine',
  'Belarus',
  'United States',
  'American Samoa',
  'Guam',
  'Northern Mariana Islands',
  'Puerto Rico',
  'Virgin Islands, U.S.',
  'United States Minor Outlying Islands',
];
