import React from 'react';

import { Link } from 'react-router-dom';
import styles from './ComingSoon.module.scss';

const ComingSoon = () => {
  return (
    <div
      className={styles.comingSoonWrap}
      //   style={{ backgroundImage: 'url(/static/images/About/nftIntroWrapBg.svg)' }}
    >
      <div className={styles.comingSoonNav}>
        <div className={styles.comingSoonNavLinks}>
          <div className={styles.halfBracketTopLeft}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
          <div className={styles.halfBracketBottomLeft}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
          <div className="">
            <a
              href="https://3base.org/about"
              target="_blank"
              rel="noreferrer noopener"
              className={styles.navLink}
              style={{ borderRight: '1px solid #1a1a1a' }}>
              About
            </a>
            <a href="mailto:contact@3base.org" className={styles.navLink}>
              Contact
            </a>
          </div>
          <div className={styles.halfBracketTopRight}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
          <div className={styles.halfBracketBottomRight}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
        </div>
        <div className={styles.comimgSoonLogo}>
          <img src="/static/images/3base-logo.png" alt="" />
        </div>
        <div className={styles.comingSoonNavLinks}>
          <div className={styles.halfBracketTopLeft}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
          <div className={styles.halfBracketBottomLeft}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
          <div className="">
            <a
              href="https://docs.3base.org/"
              className={styles.navLink}
              target="_blank"
              rel="noreferrer noopener">
              Docs
            </a>
          </div>
          <div className={styles.halfBracketTopRight}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
          <div className={styles.halfBracketBottomRight}>
            <div className={styles.halfBracketWidth} />
            <div className={styles.halfBracketHeight} />
          </div>
        </div>
      </div>
      {/* Content */}
      <div className={styles.comingSoonContent}>
        <h1 className={styles.comingSoonHeading}>Coming Soon</h1>
        <p className={styles.comingSoonParagraph}>
          We are currently working on updating our site.
          <br />
          We&apos;ll be here soon. Subscribe to be notified.
        </p>
        <a href="mailto:contact@3base.org">
          <button type="button" className={styles.notifyMeButton}>
            Notify Me
          </button>
        </a>
      </div>

      {/* Footer */}
      <div className={styles.comingSoonFooter}>
        <p>© 2024 3Base. All rights reserved.</p>
      </div>
    </div>
  );
};

export default ComingSoon;
