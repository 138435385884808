import React, { useLayoutEffect, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col, message } from 'antd';
import classNames from 'classnames';
import { ethers } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import TitleCombo from '../../components/UI/TitleCombo/TitleCombo';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import First from './First.png';
import Second from './Second.png';
import Third from './Third.png';
import FirstBg from './FirstBackground.png';
import SecondBg from './SecondBackground.png';
import ThirdBg from './ThirdBackground.png';
import { useGetPurchaseInfo } from '../../hooks/useManager';
import { formatNumber, truncAddress } from '../../utils/utils';
import { ZPOINTSTATISTIC_POINT_KEYS } from '../../constants/misc';
import { usePoints } from '../../hooks/usePoints';
import { useWallet } from '../../store/wallet-context';
import NavLinkWithQuery from '../../components/NavLinkWithQuery/NavLinkWithQuery';
import HashLinkWithQuery from '../../components/HashLinkWithQuery/HashLinkWithQuery';

import styles from './Points.module.scss';

export default function Points() {
  const { data: purchaseInfo } = useGetPurchaseInfo();
  const { account } = useWallet();
  const { t } = useTranslation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { rankList, myRanking, getPointsRanking, getMyRanking } = usePoints();
  useEffect(() => {
    getPointsRanking();
    getMyRanking();
  }, [getPointsRanking, getMyRanking]);

  function formatPoints(str) {
    return str ? formatNumber(formatEther(str), '0') : '-';
  }
  return (
    <div className={styles.Points}>
      <Row className={styles.banner} justify="center">
        <MainContainer className={styles.bannerTextContainer}>
          <Row className={styles.bannerText}>
            <Col>
              <TitleCombo>
                <h2>{t('3Base Points System', '3Base Points System')}</h2>
                <p>
                  <Trans i18nKey="pointsExplanation">
                    3Base Points are a voucher system designed to reward those who buy Zepoch Node
                    NFT’s or refer others to purchase them. The more 3Base Points you have, the
                    bigger rewards you will receive from 3Base airdrops. 3Base Points measure
                    contributions to the community in order to incentivize 3Base ecosystem’s growth.
                  </Trans>
                </p>
              </TitleCombo>
            </Col>
          </Row>
        </MainContainer>
        <Col className={styles.bannerContainer}>
          <Row className={styles.rankContainer}>
            <Col span={24}>
              <Row className={styles.mobileJustifyCenter}>
                <h2 className={styles.secondaryTitle}>
                  {t('3Base Points Leaderboard', '3Base Points Leaderboard')}
                </h2>
              </Row>
            </Col>
            <Col span={24} md={9}>
              <div className={styles.rankListTopThree}>
                <div className={classNames(styles.rankRow, styles.first)}>
                  <div className={styles.rankItem}>
                    <img className={styles.rankOrdinal} src={First} alt="first" />
                    <img className={styles.rankItemBg} src={FirstBg} alt="bg" />
                    <div className={styles.rankItemText}>
                      <p
                        className={classNames({
                          [styles.myAddress]: rankList?.[0]?.isCurrentUser,
                        })}>
                        {truncAddress(rankList?.[0]?.address)}{' '}
                        {rankList?.[0]?.isCurrentUser && `(${t('you', 'you')})`}
                      </p>
                      <p>{formatPoints(rankList?.[0]?.points)}</p>
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.rankRow, styles.second)}>
                  <div className={styles.rankItem}>
                    <img className={styles.rankOrdinal} src={Second} alt="first" />
                    <img className={styles.rankItemBg} src={SecondBg} alt="bg" />
                    <div className={styles.rankItemText}>
                      <p
                        className={classNames({
                          [styles.myAddress]: rankList?.[1]?.isCurrentUser,
                        })}>
                        {truncAddress(rankList?.[1]?.address)}{' '}
                        {rankList?.[1]?.isCurrentUser && `(${t('you', 'you')})`}
                      </p>
                      <p>{formatPoints(rankList?.[1]?.points)}</p>
                    </div>
                  </div>
                  <div className={styles.rankItem}>
                    <img className={styles.rankOrdinal} src={Third} alt="first" />
                    <img className={styles.rankItemBg} src={ThirdBg} alt="bg" />
                    <div className={styles.rankItemText}>
                      <p
                        className={classNames({
                          [styles.myAddress]: rankList?.[2]?.isCurrentUser,
                        })}>
                        {truncAddress(rankList?.[2]?.address)}{' '}
                        {rankList?.[2]?.isCurrentUser && `(${t('you', 'you')})`}
                      </p>
                      <p>{formatPoints(rankList?.[2]?.points)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} md={15}>
              <div className={styles.rankList}>
                <Row>
                  <Col span={4} className={styles.rankListHeader}>
                    {t('Rank', 'Rank')}
                  </Col>
                  <Col span={15} className={styles.rankListHeader}>
                    {t('Address', 'Address')}
                  </Col>
                  <Col span={5} className={styles.rankListHeader}>
                    {t('Points', 'Points')}
                  </Col>
                </Row>
                {rankList.slice(3).map(({ address, points, isCurrentUser, id }, index) => (
                  <Row key={id}>
                    <Col
                      span={4}
                      className={classNames(styles.rankListIndex, {
                        [styles.myAddress]: isCurrentUser,
                      })}>
                      {index + 4}
                    </Col>
                    <Col
                      span={15}
                      className={classNames(styles.rankListAddress, {
                        [styles.myAddress]: isCurrentUser,
                      })}>
                      {truncAddress(address)} {isCurrentUser && '(you)'}
                    </Col>
                    <Col
                      span={5}
                      className={classNames(styles.rankListPoints, {
                        [styles.myAddress]: isCurrentUser,
                      })}>
                      {formatPoints(points)}
                    </Col>
                  </Row>
                ))}
                {account && myRanking?.ranking > 10 ? (
                  <Row className={styles.rankListMe}>
                    <Col span={4} className={styles.rankListIndex}>
                      {formatNumber(myRanking.ranking)}
                    </Col>
                    <Col span={15} className={styles.rankListAddress}>
                      <span className={styles.rankListMyAddress}>
                        {truncAddress(myRanking.address)}({t('you', 'you')})
                      </span>
                    </Col>
                    <Col span={5} className={styles.rankListPoints}>
                      {formatPoints(myRanking.points)}
                    </Col>
                  </Row>
                ) : (
                  <hr style={{ border: 'none' }} />
                )}
              </div>
              <div className={styles.rankListTip}>
                <Trans i18nKey="rankListTip">
                  The points leaderboard updates every 15 minutes.
                </Trans>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.contentContainer}>
        <Row className={styles.mobileJustifyCenter}>
          <h2 className={styles.secondaryTitle}>{t('My Points Stats', 'My Points Stats')}</h2>
        </Row>
        <Row
          className={classNames(styles.totalEarnedPoints, styles.mobileJustifyCenter)}
          align="middle">
          <Col>
            {formatNumber(
              !!Object.keys(purchaseInfo).length &&
                ethers.utils.formatEther(
                  ZPOINTSTATISTIC_POINT_KEYS.reduce(
                    (accum, key) => accum.add(purchaseInfo[key]),
                    ethers.constants.Zero,
                  ),
                ),
            )}
          </Col>
          <Col>&nbsp; {t('3Base Points', '3Base Points')}</Col>
        </Row>
        <Row gutter={16} className={styles.earnedWrap}>
          <Col span={24} md={8} className={styles.earnedSpace}>
            <div className={styles.earnedContainer}>
              <h3>{t('Earned by Purchasing', 'Earned by Purchasing')}</h3>
              <p>{formatNumber(ethers.utils.formatEther(purchaseInfo.zPointViaPurchase ?? '0'))}</p>
              <NavLinkWithQuery to="/participate">
                <CustomButton className={styles.pointsButton} rounded>
                  {t('Buy Nodes', 'Buy Nodes')}
                </CustomButton>
              </NavLinkWithQuery>
            </div>
          </Col>
          <Col span={24} md={8} className={styles.earnedSpace}>
            <div className={styles.earnedContainer}>
              <h3>{t('Earned by Referral', 'Earned by Referral')}</h3>
              <p>{formatNumber(ethers.utils.formatEther(purchaseInfo.zPointViaReferral ?? '0'))}</p>
              {account ? (
                <HashLinkWithQuery to="/profile#referral">
                  <CustomButton className={styles.pointsButton}>
                    {t('Invite Friends', 'Invite Friends')}
                  </CustomButton>
                </HashLinkWithQuery>
              ) : (
                <CustomButton
                  className={styles.pointsButton}
                  onClick={() => {
                    message.info(t('Please connect to wallet'));
                  }}>
                  {t('Invite Friends', 'Invite Friends')}
                </CustomButton>
              )}
            </div>
          </Col>
          <Col span={24} md={8} className={styles.earnedSpace}>
            <div className={styles.earnedContainer}>
              <h3>{t('Earned by Bonus', 'Earned by Bonus')}</h3>
              <p>{formatNumber(ethers.utils.formatEther(purchaseInfo.zPointViaBonus ?? '0'))}</p>
              <p className={styles.earnedDesc}>
                <Trans i18nKey="earnedDesc">
                  Top of the leaderboard will receive bonus 3Base Points.
                </Trans>
              </p>
            </div>
          </Col>
        </Row>
        {/* <CurrentBenifit /> */}
      </div>
    </div>
  );
}
