import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import About from '../views/About/About';
import Participate from '../views/Participate/Participate';
import Points from '../views/Points/Points';
import Profile from '../views/Profile/Profile';
import LearnMore from '../views/LearnMore/LearnMore';
import AirdropNew from '../views/AirdropNew/AirdropNew';
// import AirdropClaimNew from '../views/AirdropNew/Pose/AirdropClaimNew';
import Swap from '../views/Swap/Swap';
// import RewardsPool from '../views/RewardsPool/RewardsPool';
// import Test from '../views/Test/Test';
import Farm from '../views/Farm/Farm';
import TokenPoolAction from '../views/TokenPool/TokenPoolAction/TokenPoolAction';
import Claim from '../views/Claim/Claim';
import AirdropDetail from '../views/AirdropDetail/AirdropDetail';

export const usePages = () => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  const bridgeMenuItems = useMemo(
    () => [
      {
        key: '1',
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.portalbridge.com/#/transfer">
            Portal Bridge
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              resolvedLanguage === 'zh'
                ? 'https://horizon.zebec.io/docs/bsc-solana-bridge-cn.pdf'
                : 'https://medium.com/zebec-protocol/how-to-bridge-zbc-from-solana-to-binance-smart-chain-via-portal-token-bridge-and-vice-versa-ccddfdaa1aa5'
            }>
            <Trans i18nKey="Bridge Guide">Bridge Guide</Trans>
          </a>
        ),
      },
    ],

    [resolvedLanguage],
  );

  const pages = useMemo(
    () => [
      { text: 'About', path: '/about', component: About, exact: true },
      { text: 'Participate', path: '/participate', component: Participate, exact: true },
      {
        text: 'TokenPoolAction',
        path: '/farm/stake',
        component: TokenPoolAction,
        isSubPage: true,
        exact: true,
      },
      { text: 'Farm', path: '/farm', component: Farm, exact: false },
      { text: 'Airdrop', path: '/airdrop', component: AirdropNew, exact: true },
      {
        text: 'Claim',
        path: '/claim',
        component: Claim,
        exact: false,
      },
      { text: '3Base Points', path: '/points', component: Points, exact: true },
      { text: 'Swap', path: '/swap', component: Swap, exact: true },
      { text: 'Docs', href: 'https://docs.3Base.org/' },
      { text: 'Bridge', menuItems: bridgeMenuItems },
      { text: 'Profile', path: '/profile', component: Profile, protected: true, exact: true },
      { text: 'LearnMore', path: '/learnMore', component: LearnMore, isSubPage: true, exact: true },
      // { text: 'Test', path: '/test', component: Test, exact: true },
      // { text: 'AirdropClaimNew', path: '/airdrop-claim/pose', component: AirdropClaimNew, isSubPage:true },
      {
        text: 'AirdropDetail',
        path: '/airdrop/:airdropId',
        component: AirdropDetail,
        isSubPage: true,
      },
    ],
    [bridgeMenuItems],
  );
  return pages;
};
