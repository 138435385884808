import React from 'react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import styles from './Blocked.module.scss';

const Blocked = () => (
  <div className={styles.Blocked}>
    <MainContainer>
      <h2>Operation Horizon is currently not available for your area</h2>
      <h3>
        Please visit{' '}
        <a href="https://www.3base.org/" target="_blank" rel="noopener noreferrer">
          3base.org
        </a>{' '}
        to explore other 3Base products and services.
      </h3>
    </MainContainer>
  </div>
);

export default Blocked;
