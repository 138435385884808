/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { useLayoutEffect, useState, useEffect, useCallback } from 'react';
// import { BigNumber, ethers } from 'ethers';

import { formatUnits } from 'ethers/lib/utils';
import { Checkbox, message, Spin } from 'antd';
// import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import { useZBCPrice } from '../../hooks/useZBCPrice';
import TitleCombo from '../../components/UI/TitleCombo/TitleCombo';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import NumberInput from '../../components/UI/NumberInput/NumberInput';
// import Treasury from '../../components/RewardsPool/Treasury/Treasury';
import { checkTime, formatNumber } from '../../utils/utils';
import {
  useAllTokenAllowance,
  useAllTokenBalance,
  useTokenApprove,
  // useVaultBalance,
  useZepochNodeTotalSupply,
} from '../../hooks/useToken';
import { useNameservice } from '../../store/nameservice-context';
import { CONTRACT_NAME, SUPPORT_PURCHASE_TOKEN_KEY } from '../../constants/addresses';
import { useWallet } from '../../store/wallet-context';
import {
  useBuy,
  useCalcPhase2Cost,
  // useCalcPhase2CostZbc,
  useGetPhase2BuyViaZbcDiscountInMillion,
  useLockZBCOrPayUsd,
  usePhase1Config,
  usePhase2Config,
  // useTestBuyUsd,
  // useTestBuyZbc,
} from '../../hooks/useManager';
import {
  PHASE_1_NFT_AMOUNT,
  SINGLE_TRANSACTION_NFT_LIMIT,
  TOTAL_NFT_AMOUNT,
  // WHITELIST_END_TIMESTAMP,
  // WHITELIST_START_TIMESTAMP,
} from '../../constants/misc';
import {
  useIsRegistered,
  useRegisteredInvitationCodeContains,
} from '../../hooks/useInvitationCenter';
import { useQuery } from '../../hooks/useQuery';
// import { useCurrentPhase } from '../../hooks/useCurrentPhase';
import CustomInput from '../../components/UI/CustomInput/CustomInput';
import HashLinkWithQuery from '../../components/HashLinkWithQuery/HashLinkWithQuery';
import CustomModal from '../../components/UI/CustomModal/CustomModal';
import NavLinkWithQuery from '../../components/NavLinkWithQuery/NavLinkWithQuery';
// import RewardsEstimation from '../../components/Participate/RewardsEstimation/RewardsEstimation';

import styles from './Participate.module.scss';

// const { Countdown } = Statistic;

const loadingPlaceholder = (
  <div className={styles.loadingPlaceholder}>
    <Spin />
  </div>
);

function Stake() {
  const {
    t,
    // i18n: { resolvedLanguage },
  } = useTranslation();
  const queryInviterCode = useQuery()?.get('referral');
  // inputs
  const [amount, setAmount] = useState(1);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [inviterCode, setInviterCode] = useState(queryInviterCode);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedCurrencyName = selectedCurrency === CONTRACT_NAME.ZBC ? 'ZBC' : selectedCurrency;
  const [config, setConfig] = useState();
  const [getNFTSuccessModalVisible, setGetNFTSuccessModalVisible] = useState(false);
  const { account } = useWallet();
  const addresses = useNameservice();
  const { data: isRegistered } = useIsRegistered();
  // get balance and allowance
  const allTokenBalance = useAllTokenBalance();
  const allTokenAllowance = useAllTokenAllowance(addresses[CONTRACT_NAME.Manager]);

  // get total zepoch supply
  const { data: zepochNodeTotalSupply } = useZepochNodeTotalSupply();
  const { data: phase2Cost } = useCalcPhase2Cost(amount);
  const zbcPrice = useZBCPrice();

  // validate invitation
  const { isValidInvitation } = useRegisteredInvitationCodeContains();
  // approve ZBC legacy hook
  const approveZBCLegacy = useTokenApprove(addresses[CONTRACT_NAME.ZBCLegacy]);
  // approve USDT hook
  const approveUSDT = useTokenApprove(addresses[CONTRACT_NAME.USDT]);
  // approve USDC hook
  const approveUSDC = useTokenApprove(addresses[CONTRACT_NAME.USDC]);
  // approve ZBC bridged hook
  const approveZBC = useTokenApprove(addresses[CONTRACT_NAME.ZBC]);

  const approveToken = {
    [CONTRACT_NAME.ZBCLegacy]: approveZBCLegacy,
    [CONTRACT_NAME.USDT]: approveUSDT,
    [CONTRACT_NAME.USDC]: approveUSDC,
    [CONTRACT_NAME.ZBC]: approveZBC,
  };

  // get phase config
  const { data: phase1Config } = usePhase1Config();
  const { phase1StartTimestamp, phase1ZbcLockAmount, phase1SellPrice } = phase1Config;
  const { data: phase2Config } = usePhase2Config();
  const { phase2StartTimestamp } = phase2Config;
  // const currentPhase = useCurrentPhase();
  const currentPhase = 2;

  const { data: phase2BuyViaZbcDiscountInMillion } = useGetPhase2BuyViaZbcDiscountInMillion();

  // lock/buy zbc hook

  const lockZBC = useLockZBCOrPayUsd();

  const buyNFT = useBuy();

  const { state: getNFTState, [currentPhase === 1 ? 'lockZBCOrPayUsd' : 'buy']: getNFT } =
    currentPhase === 1 ? lockZBC : buyNFT;

  // test
  // const { state: testBuyZbcState, testBuyZbc } = useTestBuyZbc();
  // const { state: testBuyUsdState, testBuyUsd } = useTestBuyUsd();

  // const { data: phase2CostZbc } = useCalcPhase2CostZbc(amount, zbcPrice);
  // console.log(formatUnits(phase2CostZbc || ethers.constants.Zero, 9));

  const usdToZBC = useCallback(
    (usdCost, zbcPriceFixedPoint18) => {
      if (!(usdCost && zbcPriceFixedPoint18 && phase2BuyViaZbcDiscountInMillion)) {
        return 0;
      }
      const tempZbcPrice = Number(formatUnits(zbcPriceFixedPoint18)) || 1;
      return (usdCost * (phase2BuyViaZbcDiscountInMillion / 1_000_000)) / tempZbcPrice;
    },
    [phase2BuyViaZbcDiscountInMillion],
  );

  useEffect(() => {
    if (currentPhase) {
      const phase1UnitPrice =
        selectedCurrency === CONTRACT_NAME.ZBCLegacy ? phase1ZbcLockAmount : phase1SellPrice;
      const tempConfig = {};

      switch (currentPhase) {
        case 1:
          tempConfig.startTimestamp = phase1StartTimestamp;
          tempConfig.totalPrice = phase1UnitPrice * amount;
          // tempConfig.unitPrice = phase1ZbcLockAmount;
          tempConfig.totalNFT = PHASE_1_NFT_AMOUNT;
          // tempConfig.tagText = `stage-1-tagtext`; // Stake $ZBC to get a Zepoch Node
          tempConfig.note = ['stage-1-note', 'stage-1-note-1', 'stage-1-note-2'];
          break;
        case 2:
          tempConfig.startTimestamp = phase2StartTimestamp;
          tempConfig.totalPrice =
            selectedCurrency === CONTRACT_NAME.ZBC
              ? usdToZBC(phase2Cost, zbcPrice.zbcPriceFixedPoint18)
              : phase2Cost;
          // tempConfig.unitPrice = tempConfig.totalPrice / amount;
          tempConfig.totalNFT = TOTAL_NFT_AMOUNT;
          // tempConfig.tagText = `stage-2-tagtext`; // Buy Zepoch Node with USDT/USDC
          tempConfig.note = ['stage-2-note'];
          break;
        default:
      }
      setConfig(tempConfig);
    }
  }, [
    currentPhase,
    amount,
    phase1StartTimestamp,
    phase1ZbcLockAmount,
    phase2StartTimestamp,
    selectedCurrency,
    phase2Cost,
    phase1SellPrice,
    zbcPrice.zbcPriceFixedPoint18,
    usdToZBC,
  ]);

  // handle default selected currency
  useEffect(() => {
    if (currentPhase) {
      setSelectedCurrency(SUPPORT_PURCHASE_TOKEN_KEY[currentPhase][0]);
    }
  }, [currentPhase]);

  if (!config) {
    return loadingPlaceholder;
  }

  const { data: allowance } = allTokenAllowance[selectedCurrency];
  const { data: balance } = allTokenBalance[selectedCurrency];
  const { data: nativeBalance } = allTokenBalance.nativeBalance;
  const { state: approveTokenState, approve } = approveToken[selectedCurrency];
  const phaseStarted = checkTime(config.startTimestamp * 1000);

  const handleAgreementCheckedChange = (e) => {
    setAgreementChecked(e.target.checked);
  };

  const handleSelectedCurrencyChange = (value) => {
    setSelectedCurrency(value);
  };

  const handleInviterCodeChange = (e) => {
    setInviterCode(e.target.value);
  };

  const toggleGetNFTSuccessModalVisible = () => {
    setGetNFTSuccessModalVisible((prev) => !prev);
  };

  // const handleTestBuy = async (mode = 'usd') => {
  //   if (testBuyUsdState.isLoading || testBuyZbcState.isLoading) {
  //     return;
  //   }
  //   if (!account) {
  //     message.info(t('Please connect to wallet', 'Please connect to wallet'));
  //     return;
  //   }
  //   if (amount <= 0) {
  //     message.info(t('Please enter a valid amount', 'Please enter a valid amount'));
  //     return;
  //   }

  //   // test enough nft to sell in current phase
  //   if (amount + zepochNodeTotalSupply > config.totalNFT) {
  //     message.info(t('Not enough Zepoch Node left', 'Not enough Zepoch Node left'));
  //     return;
  //   }

  //   if (nativeBalance < 0.01) {
  //     message.info(
  //       t(
  //         "You don't have enough BNB for gas to complete the transaction",
  //         "You don't have enough BNB for gas to complete the transaction",
  //       ),
  //     );
  //     return;
  //   }

  //   let invitationCode = inviterCode;
  //   // handle invitation code
  //   if (!(isRegistered || (await isValidInvitation(invitationCode)))) {
  //     message.info(t('Invalid invitation code', 'Invalid invitation code'));
  //     return;
  //   }
  //   if (isRegistered) {
  //     invitationCode = '';
  //   }
  //   try {
  //     const testFunc = mode === 'usd' ? testBuyUsd : testBuyZbc;
  //     await testFunc(amount, invitationCode);
  //     toggleGetNFTSuccessModalVisible();
  //   } catch (e) {
  //     console.log('get node failed');
  //   }
  // };

  const handleApprove = async () => {
    if (approveTokenState.isLoading || !addresses[CONTRACT_NAME.Manager]) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance < 0.01) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    // if (!checkTime(config.startTimestamp * 1000)) {
    //   message.info(t('Not start yet', 'Not start yet'));
    //   return;
    // }
    // if (!agreementChecked) {
    //   message.info(
    //     t(
    //       'Please check the box for the risk agreement',
    //       'Please check the box for the risk agreement',
    //     ),
    //   );
    //   return;
    // }
    try {
      await approve(addresses[CONTRACT_NAME.Manager]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetNFT = async () => {
    if (
      getNFTState.isLoading ||
      !addresses[selectedCurrency] ||
      Object.keys(zbcPrice).length === 0
    ) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (!phaseStarted) {
      message.info(t('Not start yet', 'Not start yet'));
      return;
    }
    // test for agreement checked
    if (!agreementChecked) {
      message.info(
        t(
          'Please check the box for the risk agreement',
          'Please check the box for the risk agreement',
        ),
      );
      return;
    }
    if (amount <= 0) {
      message.info(t('Please enter a valid amount', 'Please enter a valid amount'));
      return;
    }
    // test enough purchase balance
    if (config.totalPrice > balance) {
      message.info(
        t('Not enough currency', 'Not enough {{currency}}', { currency: selectedCurrencyName }),
      );
      return;
    }
    // test enough nft to sell in current phase
    if (amount + zepochNodeTotalSupply > config.totalNFT) {
      message.info(t('Not enough Zepoch Node left', 'Not enough Zepoch Node left'));
      return;
    }
    // prevent out of gas
    if (amount > SINGLE_TRANSACTION_NFT_LIMIT) {
      message.info(
        t(
          'max transaction alert',
          'A maximum of {{limit}} Nodes can be acquired in a single transaction',
          { limit: SINGLE_TRANSACTION_NFT_LIMIT },
        ),
      );
      return;
    }
    if (nativeBalance < 0.015) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }

    let invitationCode = inviterCode;
    // handle invitation code
    if (!(isRegistered || (await isValidInvitation(invitationCode)))) {
      message.info(t('Invalid invitation code', 'Invalid invitation code'));
      return;
    }
    if (isRegistered) {
      invitationCode = '';
    }
    try {
      if (currentPhase === 1) {
        await getNFT(amount, invitationCode, addresses[selectedCurrency]);
      } else {
        await getNFT(amount, invitationCode, addresses[selectedCurrency], zbcPrice);
      }
      toggleGetNFTSuccessModalVisible();
    } catch (e) {
      console.log('get node failed');
    }
  };

  const getButton = () => {
    if (zepochNodeTotalSupply >= TOTAL_NFT_AMOUNT) {
      return (
        <CustomButton className={styles.BuyButton} rounded disabled>
          <div className={styles.buyButtonContent}>
            {t('Sale Ended', 'Sale Ended')}
            <br />
            <span>
              {t('Balance', 'Balance')}: {formatNumber(balance)} {selectedCurrencyName}
            </span>
          </div>
        </CustomButton>
      );
    }
    if (config.totalPrice > allowance) {
      return (
        <CustomButton
          className={styles.BuyButton}
          rounded
          loading={approveTokenState.isLoading}
          onClick={handleApprove}>
          <div className={styles.buyButtonContent}>
            {t('APPROVE', 'APPROVE')} {selectedCurrencyName} <br />
            <span>
              {t('Balance', 'Balance')}: {formatNumber(balance)} {selectedCurrencyName}
            </span>
          </div>
        </CustomButton>
      );
    }

    return (
      <CustomButton
        className={styles.BuyButton}
        rounded
        loading={getNFTState.isLoading}
        disabled={!phaseStarted}
        onClick={handleGetNFT}>
        <div className={styles.buyButtonContent}>
          {phaseStarted ? t('Get Zepoch', 'Get Zepoch Node') : t('Not start yet', 'Not start yet')}
          <br />
          <span>
            {t('Balance', 'Balance')}: {formatNumber(balance)} {selectedCurrencyName}
          </span>
        </div>
      </CustomButton>
    );
  };

  const stage1Reward = (
    <>
      <TitleCombo small>
        <h2>
          <Trans i18nKey="Stage 1 Bonus">Phase 1 Bonus: Themis Gavel </Trans>
        </h2>
        <p className={styles.ParticipateDesc}>
          <Trans i18nKey="stage-1-bonus-explanation">
            A Themis Gavel NFT will be given to all Phase 1 Zepoch Node holders for free.
          </Trans>
        </p>
      </TitleCombo>
      <div className={styles.ThemisGavel}>
        <video
          autoPlay
          muted
          loop
          playsInline
          poster="/static/images/NFT/themis.png"
          data-aos="fade-right">
          <source src="/static/images/NFT/themis.mp4" type="video/mp4" />
        </video>

        <div className={styles.ThemisGavelInfoWarp} data-aos="fade-left">
          <TitleCombo className={styles.ThemisGavelTitle}>
            <h3>
              <Trans i18nKey="ZBC Themis Gavel">Themis Gavel</Trans>
            </h3>
            <p className={styles.ParticipateDesc}>
              <Trans i18nKey="ThemisGavel">
                Themis Gavel is a soul-bound NFT using the (EIP-5114) protocol whose primary benefit
                is giving voting rights in 3Base governance process to the owner.
              </Trans>
            </p>

            <p
              className={styles.ParticipateBuyFormDesc}
              style={{
                color: 'rgba(0, 0, 0, 0.5)',
                marginTop: 0,
              }}>
              <Trans i18nKey="ParticipateBuyFormDesc">
                Note: Themis Gavel will automatically bond to the address once it is accepted and
                cannot be unbonded, transferred, or traded.
              </Trans>
            </p>
          </TitleCombo>

          <div className={classNames(styles.Attention2, styles.BlueText, styles.BlueBg)}>
            <div className={styles.AttentionTitle}>
              <Trans i18nKey="How to get a Themis Gavel NFT:">How to get Themis Gavel</Trans>
            </div>
            <div className={styles.AttentionDesc}>
              <div>
                <Trans i18nKey="Step-to-get-NFT-1">
                  1. Participate in the 1st phase of Operation Horizon – the first 288 Zepoch Node
                  holders will automatically be given a Themis Gavel for free.
                </Trans>
              </div>
              <div>
                <Trans i18nKey="Step-to-get-NFT-2">
                  2. Participate in the 2nd phase of Operation Horizon – complete specific community
                  promotion tasks and receive one when the contributions are verified.
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const stakeContent = config && (
    <>
      {/* <div className={styles.ProgressBarWarp}>
        <div className={classNames(styles.Stage, styles.Begins)}>
          {config.startTimestamp && !phaseStarted ? (
            <div className={styles.StageCountdown}>
              {`${t('Phase', 'Phase')} ${currentPhase} ${t('Begins in', 'Begins in')}:`}
              {Number.MAX_SAFE_INTEGER === config.startTimestamp ? (
                'TBD'
              ) : (
                <Countdown value={config.startTimestamp * 1000} format="D:HH:mm:ss" />
              )}
            </div>
          ) : (
            <div />
          )}
          {config.totalNFT && currentPhase === 1 && (
            <div className={styles.StageServings}>
              <span className={styles.StageServingsActive}>
                {formatNumber(zepochNodeTotalSupply)}
              </span>{' '}
              / {formatNumber(config.totalNFT)}
            </div>
          )}
        </div>
        {config.totalNFT && currentPhase === 1 && (
          <div className={styles.ProgressBar}>
            <div
              className={styles.ProgressBarActive}
              style={{ width: `${(zepochNodeTotalSupply * 100) / config.totalNFT}%` }}
            />
          </div>
        )}
      </div> */}
      <div className={classNames(styles.ParticipateCard, styles.ParticipateBuy)}>
        <div className={styles.Stage}>
          <div className={styles.StageTag}>
            {t('Phase', 'Phase')} {currentPhase}
            {/* <span>{t(config.tagText, { unitprice: formatNumber(config.unitPrice) })}</span> */}
          </div>
        </div>

        <div className={styles.ParticipateBuyWarp}>
          <div className={styles.ParticipateBuyForm}>
            <TitleCombo className={styles.ParticipateBuyFormTitle}>
              <h3>{t('Zepoch Node NFTs', 'Zepoch Node NFTs')} </h3>
              <p className={styles.ParticipateBuyFormDesc}>
                <Trans i18nKey="">
                  Zepoch Node NFTs guarantee your right to run a validator node on 3Base chain when
                  it goes live in 2024. In the meantime, holding Zepochs will give you USDT and ZBC
                  rewards now.
                </Trans>
              </p>
              <div className={styles.notes}>
                {config.note &&
                  config.note.map((note) => (
                    <p key={note} className={styles.ParticipateBuyFormDesc}>
                      {t(note)}
                    </p>
                  ))}
              </div>
            </TitleCombo>
            <div className={styles.Price}>
              <div />
              {/* <div>
                <div className={styles.StageServings}>
                  <span className={styles.StageServingsActive}>
                    {formatNumber(zepochNodeTotalSupply)}
                  </span>{' '}
                  / {formatNumber(config.totalNFT)} {t('nodes sold', 'sold')}
                </div>
              </div> */}
              {/* {config.phase === 1 && (
                <div>
                  {t('Price', 'Price')} : {formatNumber(config.unitPrice)} {config.currency}
                </div>
              )} */}
              <div className={styles.currencySelector}>
                {SUPPORT_PURCHASE_TOKEN_KEY[currentPhase].map((item) => {
                  const currencyDisplayName = item === CONTRACT_NAME.ZBC ? 'ZBC' : item;
                  return (
                    <div
                      className={classNames(styles.optionItem, {
                        [styles.active]: selectedCurrency === item,
                      })}
                      key={item}
                      onClick={() => {
                        handleSelectedCurrencyChange(item);
                      }}>
                      <img
                        src={`/static/images/TokenIcon/${currencyDisplayName.toLowerCase()}.svg`}
                        alt={item}
                      />
                      <span>{currencyDisplayName}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <NumberInput value={amount} onChange={(v) => setAmount(v)} />
            {!isRegistered && (
              <CustomInput
                placeholder={t('Enter Your Invitation Code', 'Enter Your Invitation Code')}
                disabled={!!queryInviterCode}
                value={inviterCode}
                onChange={handleInviterCodeChange}
              />
            )}
            {/* <RewardsEstimation amount={amount} /> */}
            <div className={styles.Cost}>
              <span>{t('Cost', 'Cost')}:</span>
              <span>
                {formatNumber(config.totalPrice)} {selectedCurrencyName}
              </span>
            </div>

            <div className={styles.Protocol}>
              <Checkbox onChange={handleAgreementCheckedChange}>
                <Trans i18nKey="Agreement" checked>
                  I understand that by participating I assume all risks associated with this
                  campaign
                </Trans>
              </Checkbox>
            </div>
            {getButton()}
            {/* Test */}
            {/* <CustomButton
              onClick={() => {
                handleTestBuy('zbc');
              }}>
              {' '}
              Test Buy ZBC
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleTestBuy('usd');
              }}>
              {' '}
              Test Buy USD
            </CustomButton> */}

            {selectedCurrency && (
              <a
                href={`https://pancakeswap.finance/swap?outputCurrency=${addresses[selectedCurrency]}`}
                className={styles.getTokenLink}
                target="_blank"
                rel="noopener noreferrer">
                {t('Get', 'Get')} {selectedCurrencyName}
              </a>
            )}
          </div>
          <div className={styles.ParticipateBuyImageWarp} data-aos="fade-left">
            <video autoPlay muted loop playsInline poster="/static/images/NFT/zepoch.png">
              <source src="/static/images/NFT/zepoch.mp4" type="video/mp4" />
            </video>
            <div className={styles.Attention}>
              <div className={styles.AttentionTitle}>{t('Attention', 'Attention')}</div>
              <div className={styles.AttentionDesc}>
                <Trans i18nKey="PledegExplaination">
                  The Zepoch Node NFT cannot be transferred or traded until 3Base Chain is live. (or
                  before all 30,000 Zepoch Node NFTs are sold.)
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const invite = (
    <div className={styles.Invite}>
      <div className={styles.InviteContentWarp}>
        <div className={styles.InviteLeft}>
          <div className={styles.logoWrap}>
            <img src="/static/images/About/logo.svg" alt="logo" />
            {/* <h2>Zebec</h2> */}
          </div>
          <div className={styles.InviteTitle}>
            <div className={styles.InviteTitleText}>{t('Points Giveaway', 'Points Giveaway')}</div>
            <div className={styles.Rrewards}>
              <img
                className={styles.InviteLogo}
                src="/static/images/Participate/pointIcon.svg"
                alt="zebec"
              />
              5% {t('Referral Rewards', 'USDT/USDC Referral Rewards')}
            </div>
            {account ? (
              <HashLinkWithQuery to="/profile#referral">
                <CustomButton className={styles.buyButton} rounded>
                  <span className={styles.buyButtonText}>{t('Invite now', 'Invite now')}</span>
                </CustomButton>
              </HashLinkWithQuery>
            ) : (
              <CustomButton
                className={styles.buyButton}
                rounded
                onClick={() => {
                  message.info(t('Please connect to wallet'));
                }}>
                <span className={styles.buyButtonText}>{t('Invite now', 'Invite now')}</span>
              </CustomButton>
            )}
          </div>
          <div className={styles.InviteDesc}>
            <Trans i18nKey="ReferralBonus">
              Each successful Zepoch Node NFT referred sale will award the referrer 1000 3Base
              Points.
            </Trans>
          </div>
        </div>
      </div>
      <img
        className={styles.InviteTextBg}
        src="/static/images/About/saleWatermark.svg"
        alt="nodeonSale"
      />
      {/* <img
        className={styles.InviteAwesome}
        src="/static/images/Participate/awesome.png"
        alt="zebec"
      /> */}
    </div>
  );

  const getContent = () => {
    switch (currentPhase) {
      case 1:
        return (
          <>
            {stakeContent} {stage1Reward}
          </>
        );
      case 2:
        return stakeContent;
      default:
        return loadingPlaceholder;
    }
  };
  return (
    <>
      {/* <div
        className={styles.stakeHeader}
        style={{ backgroundImage: 'url(/static/images/Participate/stakeHeaderBg.png)' }}>
        <TitleCombo>
          <h2>{t('Get a Zepoch NFT and Start Earning', 'Get Zepoch Node and Start Earning')}</h2>
          <a
            className={styles.guideLink}
            href={`https://horizon.zebec.io/docs/ZepochNodeTutorial${
              resolvedLanguage === 'zh' ? 'CN' : 'EN'
            }.pdf`}
            target="_blank"
            rel="noopener noreferrer">
            <QuestionCircleFilled />
            {t('How to Get Zepoch?', 'How to Get Zepoch?')}
          </a>
        </TitleCombo>
        <MainContainer className={styles.treasuryContainer}>
          <Treasury />
        </MainContainer>
      </div> */}
      {getContent()}
      {invite}
      <CustomModal open={getNFTSuccessModalVisible} onCancel={toggleGetNFTSuccessModalVisible}>
        <div className={styles.successModal}>
          <TitleCombo small>
            <h2>Congratulations</h2>
            <p>Please find the Zepoch Nodes in your profile</p>
          </TitleCombo>
          <NavLinkWithQuery to="/profile">
            <CustomButton>View Profile</CustomButton>
          </NavLinkWithQuery>
        </div>
      </CustomModal>
    </>
  );
}

// function Coming() {
//   const whitelistStarted = checkTime(WHITELIST_START_TIMESTAMP * 1000);
//   const { t } = useTranslation();

//   return (
//     <div className={styles.Coming}>
//       <TitleCombo className={styles.ComingWarp}>
//         <h2>{t('Zebec Operation Horizon Whitelist', 'Zebec Operation Horizon Whitelist')}</h2>
//         <a
//           className={styles.learnMoreLink}
//           href="https://medium.com/zebec-protocol/announcing-operation-horizon-co-build-an-ecosystem-with-zebec-c58b89431fb"
//           target="_blank"
//           rel="noopener noreferrer">
//           {t('Learn More', 'Learn More')}
//         </a>
//         {whitelistStarted ? (
//           <>
//             <div className={styles.CountDown}>
//               <span>{t('Ends in', 'Ends in')}:</span>
//               <Countdown value={WHITELIST_END_TIMESTAMP * 1000} format="D:HH:mm:ss" />
//             </div>
//             <a
//               href="https://notionforms.io/forms/whitelist-1"
//               target="_blank"
//               rel="noopener noreferrer">
//               <CustomButton rounded>{t('Join the Whitelist', 'Join the Whitelist')}</CustomButton>
//             </a>
//           </>
//         ) : (
//           <>
//             <div className={styles.CountDown}>
//               <span>{t('Opens in', 'Opens in')}:</span>
//               <Countdown value={WHITELIST_START_TIMESTAMP * 1000} format="D:HH:mm:ss" />
//             </div>
//             <CustomButton
//               rounded
//               disabled
//               onClick={() => {
//                 message.info(t('Open Soon', 'Open Soon'));
//               }}>
//               {t('Join the Whitelist', 'Join the Whitelist')}
//             </CustomButton>
//           </>
//         )}
//       </TitleCombo>
//       <img src="/static/images/Participate/countdownBg.png" alt="nodeonSale" />
//     </div>
//   );
// }

const Participate = () => {
  // const whiteListEnded = checkTime(WHITELIST_END_TIMESTAMP * 1000);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer className={styles.Participate} data-aos="fade-up">
      {/* {whiteListEnded ? <Stake /> : <Coming />} */}
      <Stake />
    </MainContainer>
  );
};

export default Participate;
